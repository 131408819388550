html{
  background-color: #FFD300;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.nav-custom{
  background-color: #FFD300 !important;
}


.dynamicTitle{
  text-align: center;
  font-size: 5vw;
  color: #343a40;
  font-weight: bold;
}

.emojiContainer{
  align-content: center;
  text-align: center;
}

.profilePicFrame{
  background-image: linear-gradient(to right,#343a40, #343a40);
  height: 320px;
  width: 320px;
  position: relative;
  margin:0 auto;
  border-radius: 50%;
}

.profilePic{
  height:300px;
  width: 300px;
  border-radius: 50%;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}